if (document.getElementById('registration-form') !== null) {
    new Vue({
        el: '#registration-form',
        data: function () {
            return {
                errors: ['', '', '', '', ''],
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirm: ''

            };
        },
        watch: {
            firstName: function (newVal) {
                this.errors[0] = this.validateEmpty('First name', newVal);
            },
            lastName: function (newVal) {
                this.errors[1] = this.validateEmpty('Last name', newVal);
            },
            email: function (newVal) {
                this.errors[2] = this.validateEmail(newVal);
            },
            password: function (newVal) {
                this.errors[3] = this.validatePassword(newVal);
                this.errors[4] = this.validateConfirm();
            },
            confirm: function (newVal) {
                this.errors[4] = this.validateConfirm(newVal);
            }
        },
        methods: {
            checkForm: function (e) {
                return this.errors.join('').length > 0 ? e.preventDefault() : true;
            },
            validateEmpty(field, value) {
                let message = '';
                if (value.length === 0) {
                    message = `${field} is required.`;
                }
                return message;
            },
            validateEmail(value) {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                let result = re.test(String(value).toLowerCase());
                let message = '';
                if (!result) {
                    message = 'Please enter a valid email.';
                }
                return message;
            },
            validatePassword(value) {
                let message = '';
                if (value.length < 6) {
                    message = 'Password must be at least 6 characters.';
                }
                return message;
            },
            validateConfirm(value = this.confirm) {
                let message = '';
                if (value !== this.password) {
                    message = "The password doesn't match.";
                }
                return message;
            }
        }
    });
}
if (document.getElementById('achievement-create-form') !== null) {
    new Vue({
        el: '#achievement-create-form',
        data: function () {
            return {
                errors: {
                    result: null,
                    title: null,
                    type: null,
                    year: null
                },
                title: '',
                type: 'Award',
                url: '/api/Achievement',
                year: '2000'
            };
        },
        methods: {
            checkForm() {
                const title = this.validateTitle();
                const type = this.validateType();
                const year = this.validateYear();
                return title && type && year;
            },
            submit() {
                if (this.checkForm()) {
                    common.post(this.url, { Title: this.title, Type: this.type, Year: this.year })
                        .then(() => window.location.href = "Index")
                        .catch(response => this.errors.result = response.error.message);
                }
            },
            validateTitle() {
                if (this.title.length > 0) {
                    this.errors.title = null;
                    return true;
                }
                this.errors.title = "Title is required";
                return false;
            },
            validateType() {
                if (this.type.length > 0) {
                    this.errors.type = null;
                    return true;
                }
                this.errors.type = "Type is required";
                return false;
            },
            validateYear() {
                if (parseInt(this.year)) {
                    this.errors.year = null;
                    return true;
                }
                this.errors.year = "Year must be a number.";
                return false;
            }
        },
        watch: {
            title: 'validateTitle',
            type: 'validateType',
            year: 'validateYear'
        }
    });
}
if (document.getElementById('achievement-delete-form') !== null) {
    new Vue({
        el: '#achievement-delete-form',
        beforeMount() {
            this.id = this.$el.querySelector('#Id').value;
        },
        data: function () {
            return {
                errors: {
                    result: null
                },
                id: '',
                url: '/api/Achievement'
            };
        },
        methods: {
            submit() {
                common.delete(`${this.url}(${this.id})`)
                    .then(() => window.location.href = "/Achievement/Index")
                    .catch(response => this.errors.result = response.error.message);
            }
        }
    });
}
if (document.getElementById('achievement-edit-form') !== null) {
    new Vue({
        el: '#achievement-edit-form',
        beforeMount() {
            this.id = this.$el.querySelector('#Id').value;
            this.title = this.$el.querySelector('#Title').value;
            this.type = this.$el.querySelector('#Type').value;
            this.year = this.$el.querySelector('#Year').value;
        },
        data: function () {
            return {
                errors: {
                    result: null,
                    title: null,
                    type: null,
                    year: null
                },
                id: '',
                title: '',
                type: '',
                url: '/api/achievement',
                year: ''
            };
        },
        methods: {
            checkForm() {
                const title = this.validateTitle();
                const type = this.validateType();
                const year = this.validateYear();
                return title && type && year;
            },
            submit() {
                if (this.checkForm()) {
                    common.put(`${this.url}(${this.id})`, { Id:this.id, Title: this.title, Type: this.type, Year: this.year })
                        .then(() => window.location.href = "/Achievement/Index")
                        .catch(response => console.error(response.error.message));
                }
            },
            validateTitle() {
                if (this.title.length > 0) {
                    this.errors.title = null;
                    return true;
                }
                this.errors.title = "Title is required";
                return false;
            },
            validateType() {
                if (this.type.length > 0) {
                    this.errors.type = null;
                    return true;
                }
                this.errors.type = "Type is required";
                return false;
            },
            validateYear() {
                if (parseInt(this.year)) {
                    this.errors.year = null;
                    return true;
                }
                this.errors.year = "Year must be a number.";
                return false;
            }
        },
        watch: {
            title: 'validateTitle',
            type: 'validateType',
            year: 'validateYear'
        }
    });
}
if (document.getElementById('achievement-management') !== null) {
    new Vue({
        el: '#achievement-management',
        components: {
            tableComponent
        },
        data: function () {
            return {
                options: {
                    endpoint: '/api/Achievement',
                    dataset: {
                        actions: {
                            details: { enabled: true, url: 'Details' },
                            edit: { enabled: true, url: 'Edit' },
                            remove: { enabled: true, url: 'Delete' }
                        },
                        hide:['Id']
                    },
                    key: 'Id',
                    ribbon: [{
                        url: 'Create',
                        icon: 'far fa-plus'
                    }]
                }
            };
        },
        mounted: function () {
        },
        methods: {
            isEmpty() {
                return !this.data.length > 0;
            }
        }
    });
}
if (document.getElementById('education-create-form') !== null) {
    new Vue({
        el: '#education-create-form',
        beforeMount() {
            common.get(this.endpoint)
                .then(response => this.source = response.value)
                .catch(error => console.error(error));
        },
        components: {
            galleryPickerComponent
        },
        data: function () {
            return {
                
                endpoint: '/api/MediaLink?$expand=Group',
                errors: {
                    imageUrl: null,
                    link: null,
                    result:null,
                    school: null,
                    title: null,
                    year:null
                },
                imageUrl: '',
                link: '',
                school: '',
                source: [],
                title:'',
                url: '/api/Education',
                urlRegex:/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                year:'2000'
            };
        },
        methods: {
            checkForm() {
                const imageUrl = this.validateImageUrl();
                const link = this.validateLink();
                const school = this.validateSchool();
                const title = this.validateTitle();
                const year = this.validateYear();
                return imageUrl && link && school && title && year;
            },
            submit() {
                if (this.checkForm()) {
                    common.post(this.url, { School: this.school, Link: this.link, Title: this.title, ImageUrl: this.imageUrl, Year: this.year })
                        .then(() => window.location.href = "Index")
                        .catch(response => this.errors.result = response.error.message);
                }
            },
            validateImageUrl() {
                if (this.imageUrl.length > 0) {
                    this.errors.imageUrl = null;
                    return true;
                }
                this.errors.imageUrl = "Image Url is required.";
                return false;
            },
            validateLink() {
                if (this.link.length === 0) {
                    this.errors.link = "Link is required.";
                    return false;
                }
                if (!this.link.match(this.urlRegex)) {
                    this.errors.link = "Link must be a website url";
                    return false;
                }
                this.errors.link = null;
                return true;
            },
            validateSchool() {
                if (this.school.length > 0) {
                    this.errors.school = null;
                    return true;
                }
                this.errors.school = "School is required.";
                return false;
            },
            validateTitle() {
                if (this.title.length > 0) {
                    this.errors.title = null;
                    return true;
                }
                this.errors.title = "Title is required";
                return false;
            },
            validateYear() {
                if (parseInt(this.year)) {
                    this.errors.year = null;
                    return true;
                }
                this.errors.year = "Year must be a number.";
                return false;
            }
        },
        watch: {
            imageUrl: 'validateImageUrl',
            link: 'validateLink',
            school: 'validateSchool',
            title: 'validateTitle',
            year: 'validateYear'
        }
    });
}
if (document.getElementById('education-delete-form') !== null) {
    new Vue({
        el: '#education-delete-form',
        beforeMount() {
            this.id = this.$el.querySelector('#Id').value;
        },
        data: function () {
            return {
                errors: {
                    result: null
                },
                id: '',
                url: '/api/Education'
            };
        },
        methods: {
            submit() {
                common.delete(`${this.url}(${this.id})`)
                    .then(() => window.location.href = "/Education/Index")
                    .catch(response => this.errors.result = response.error.message);
            }
        }
    });
}
if (document.getElementById('education-edit-form') !== null) {
    new Vue({
        el: '#education-edit-form',
        beforeMount() {
            this.id = this.$el.querySelector('#Id').value;
            this.imageUrl = this.$el.querySelector('#ImageUrl').value;
            this.link = this.$el.querySelector('#Link').value;
            this.school = this.$el.querySelector('#School').value;
            this.title = this.$el.querySelector('#Title').value;
            this.year = this.$el.querySelector('#Year').value;

            common.get(this.endpoint)
                .then(response => this.source = response.value)
                .catch(error => console.error(error));
        },
        components: {
            galleryPickerComponent
        },
        data: function () {
            return {

                endpoint: '/api/MediaLink?$expand=Group',
                errors: {
                    imageUrl: null,
                    link: null,
                    result: null,
                    school: null,
                    title: null,
                    year: null
                },
                id:'',
                imageUrl: '',
                link: '',
                school: '',
                source: [],
                title: '',
                url: '/api/Education',
                urlRegex: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                year: ''
            };
        },
        methods: {
            checkForm() {
                const imageUrl = this.validateImageUrl();
                const link = this.validateLink();
                const school = this.validateSchool();
                const title = this.validateTitle();
                const year = this.validateYear();
                return imageUrl && link && school && title && year;
            },
            submit() {
                if (this.checkForm()) {
                    common.put(`${this.url}(${this.id})`, { Id: this.id, School: this.school, Link: this.link, Title: this.title, ImageUrl: this.imageUrl, Year: this.year })
                        .then(() => window.location.href = "/Education/Index")
                        .catch(response => this.errors.result = response.error.message);
                }
            },
            validateImageUrl() {
                if (this.imageUrl.length > 0) {
                    this.errors.imageUrl = null;
                    return true;
                }
                this.errors.imageUrl = "Image Url is required.";
                return false;
            },
            validateLink() {
                if (this.link.length === 0) {
                    this.errors.link = "Link is required.";
                    return false;
                }
                if (!this.link.match(this.urlRegex)) {
                    this.errors.link = "Link must be a website url";
                    return false;
                }
                this.errors.link = null;
                return true;
            },
            validateSchool() {
                if (this.school.length > 0) {
                    this.errors.school = null;
                    return true;
                }
                this.errors.school = "School is required.";
                return false;
            },
            validateTitle() {
                if (this.title.length > 0) {
                    this.errors.title = null;
                    return true;
                }
                this.errors.title = "Title is required";
                return false;
            },
            validateYear() {
                if (parseInt(this.year)) {
                    this.errors.year = null;
                    return true;
                }
                this.errors.year = "Year must be a number.";
                return false;
            }
        },
        watch: {
            imageUrl: 'validateImageUrl',
            link: 'validateLink',
            school: 'validateSchool',
            title: 'validateTitle',
            year: 'validateYear'
        }
    });
}
if (document.getElementById('education-management') !== null) {
    new Vue({
        el: '#education-management',
        components: {
            tableComponent
        },
        data: function () {
            return {
                options: {
                    endpoint: '/api/Education',
                    dataset: {
                        actions: {
                            details: { enabled: true, url: 'Details' },
                            edit: { enabled: true, url: 'Edit' },
                            remove: { enabled: true, url: 'Delete' }
                        },
                        hide:['Id', 'ImageUrl']
                    },
                    key:'Id',
                    ribbon: [{
                        url: 'Create',
                        icon: 'far fa-plus'
                    }]
                }
            };
        },
        mounted: function () {
        },
        methods: {
            isEmpty() {
                return !this.data.length > 0;
            }
        }
    });
}
if (document.getElementById('contact-section') !== null) {
    new Vue({
        el: "#contact-section",
        data: function () {
            return {
                email: '',
                errors: {
                    email: null,
                    name: null,
                    message: null,
                    token: null
                },
                loading: false,
                name: '',
                message: '',
                token: '',
                url: '/api/Email'
            };
        },
        methods: {
            checkForm() {
                const email = this.validateEmail();
                const name = this.validateName();
                const message = this.validateMessage();
                const token = this.validateToken();

                return email && name && message && token;
            },
            clearErrors() {
                this.errors.email = null;
                this.errors.name = null;
                this.errors.message = null;
            },
            resetForm() {
                this.email = '';
                this.name = '';
                this.message = '';
                this.token = '';
                grecaptcha.enterprise.reset();
            },
            submit() {
                if (this.checkForm()) {
                    this.loading = true;
                    common.post(this.url, { Email: this.email, Name: this.name, Message: this.message, Token: this.token })
                        .then(response => {
                            this.resetForm();
                            this.clearErrors();
                            Vue.$toast.success("Message Sent!");
                        })
                        .catch(response => console.error(response))
                        .finally(() => this.loading = false);
                }
            },
            validateEmail() {
                if (this.email.length > 0) {
                    this.errors.email = null;
                    return true;
                }
                this.errors.email = "Email is required.";
                return false;
            },
            validateName() {
                if (this.name.length > 0) {
                    this.errors.name = null;
                    return true;
                }
                this.errors.name = "Name is required.";
                return false;
            },
            validateMessage() {
                if (this.message.length > 0) {
                    this.errors.message = null;
                    return true;
                }

                this.errors.message = "Message is required.";
                return false;
            },
            validateToken() {
                this.token = this.$el.querySelector('#g-recaptcha-response').value;
                if (this.token.length > 0) {
                    this.errors.token = null;
                    return true;
                }

                this.errors.token = "Please complete the ReCaptcha.";
                return false;

            }
        }
    });

    //ReScale the recaptcha for smaller devices.
    $(function () {
        function rescaleCaptcha() {
            var width = $('.g-recaptcha').parent().width();
            var scale;
            if (width < 302) {
                scale = width / 302;
            } else {
                scale = 1.0;
            }

            $('.g-recaptcha').css('transform', 'scale(' + scale + ')');
            $('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
            $('.g-recaptcha').css('transform-origin', '0 0');
            $('.g-recaptcha').css('-webkit-transform-origin', '0 0');
        }

        rescaleCaptcha();
        $(window).resize(function () { rescaleCaptcha(); });

    });
}

if (document.getElementById('job-create-form') !== null) {
    new Vue({
        el: '#job-create-form',
        components: {
            vuejsDatepicker: window.vuejsDatepicker
        },
        data: function () {
            return {
                company: '',
                dateFormat: 'MMMM yyyy',
                description: '',
                errors: {
                    company: null,
                    description: null,
                    jobEnd: null,
                    jobStart: null,
                    result: null,
                    title: null
                },
                jobEnd: null,
                jobStart: null,
                title: '',
                url: '/api/Job'
            };
        },
        methods: {
            checkForm() {
                const company = this.validateCompany();
                const description = this.validateDescription();
                const jobEnd = this.validateJobEnd();
                const jobStart = this.validateJobStart();
                const title = this.validateTitle();
                return company && description && jobEnd && jobStart && title;
            },
            submit() {
                if (this.checkForm()) {
                    common.post(this.url, { Company: this.company, Description: this.description, JobEnd: this.jobEnd, JobStart: this.jobStart, Title: this.title })
                        .then(() => window.location.href = "Index")
                        .catch(response => console.error(response.error.message));
                }
            },
            validateCompany() {
                if (this.company.length > 0) {
                    this.errors.company = null;
                    return true;
                }
                this.errors.company = 'Company name is required.';
                return false;
            },
            validateDescription() {
                if (this.description.length > 0) {
                    this.errors.description = null;
                    return true;
                }
                this.errors.description = 'Job description is required.';
                return false;

            },
            validateJobEnd() {
                if (this.jobStart && this.jobEnd && !this.validateDateMatch()) {
                    this.errors.jobEnd = 'End date cannot be before start date.';
                    return false;
                }
                this.errors.jobEnd = null;
                return true;
            },
            validateJobStart() {
                if (this.jobStart && this.jobEnd && !this.validateDateMatch()) {
                    this.errors.jobStart = 'Start date cannot be after end date.';
                    return false;
                }
                else if (!this.jobStart) {
                    this.errors.jobStart = 'Start date is required.';
                    return false;
                }

                this.errors.jobStart = null;
                return true;
            },
            validateTitle() {
                if (this.title.length > 0) {
                    this.errors.title = null;
                    return true;
                }
                this.errors.title = "Title is required";
                return false;
            },
            validateDateMatch() {
                if (this.jobEnd < this.jobStart) {
                    return false;
                }
                return true;
            },
            validateDates() {
                this.validateJobEnd();
                this.validateJobStart();
            }
        },
        watch: {
            company: 'validateCompany',
            description: 'validateDescription',
            jobEnd: 'validateDates',
            jobStart: 'validateDates',
            title: 'validateTitle'
        }
    });
}
if (document.getElementById('job-delete-form') !== null) {
    new Vue({
        el: '#job-delete-form',
        beforeMount() {
            this.id = this.$el.querySelector('#Id').value;
        },
        data: function () {
            return {
                errors: {
                    result: null
                },
                id: '',
                url: '/api/Job'
            };
        },
        methods: {
            submit() {
                common.delete(`${this.url}(${this.id})`)
                    .then(() => window.location.href = "/Job/Index")
                    .catch(response => this.errors.result = response.error.message);
            }
        }
    });
}
if (document.getElementById('job-edit-form') !== null) {
    new Vue({
        el: '#job-edit-form',
        beforeMount() {
            let JobEnd = this.$el.querySelector('#JobEnd').value;
            
            this.company = this.$el.querySelector('#Company').value;
            this.description = this.$el.querySelector('#Description').value;
            this.id = this.$el.querySelector('#Id').value;
            this.jobEnd = null;
            this.jobStart = new Date(Date.parse(this.$el.querySelector('#JobStart').value)) || null;
            this.title = this.$el.querySelector('#Title').value;

            if (JobEnd.length > 0) {
                this.jobEnd = new Date(Date.parse(this.$el.querySelector('#JobEnd').value)) || null;
            }

        },
        components: {
            vuejsDatepicker: window.vuejsDatepicker
        },
        data: function () {
            return {
                company: '',
                description: '',
                dateFormat: 'MMMM yyyy',
                errors: {
                    company: null,
                    description:null,
                    jobEnd: null,
                    jobStart: null,
                    result: null,
                    title: null
                },
                id: '',
                jobEnd: '',
                jobStart:'',
                title: '',
                url: '/api/Job'
            };
        },
        methods: {
            checkForm() {
                const company = this.validateCompany();
                const description = this.validateDescription();
                const jobEnd = this.validateJobEnd();
                const jobStart = this.validateJobStart();
                const title = this.validateTitle();
                return company && description && jobEnd && jobStart && title;
            },
            submit() {
                if (this.checkForm()) {
                    common.put(`${this.url}(${this.id})`, { Company: this.company, Description: this.description, Id: this.id, JobEnd: this.jobEnd, JobStart: this.jobStart, Title: this.title })
                        .then(() => window.location.href = "/Job/Index")
                        .catch(response => console.error(response.error.message));
                }
            },
            validateCompany() {
                if (this.company.length > 0) {
                    this.errors.company = null;
                    return true;
                }
                this.errors.company = 'Company name is required.';
                return false;
            },
            validateDescription() {
                if (this.description.length > 0) {
                    this.errors.description = null;
                    return true;
                }
                this.errors.description = 'Job description is required.';
                return false;

            },
            validateJobEnd() {
                if (this.jobStart && this.jobEnd && !this.validateDateMatch()) {
                    this.errors.jobEnd = 'End date cannot be before start date.';
                    return false;
                }
                this.errors.jobEnd = null;
                return true;
            },
            validateJobStart() {
                if (this.jobStart && this.jobEnd && !this.validateDateMatch()) {
                    this.errors.jobStart = 'Start date cannot be after end date.';
                    return false;
                }
                else if (!this.jobStart) {
                    this.errors.jobStart = 'Start date is required.';
                    return false;
                }

                this.errors.jobStart = null;
                return true;
            },
            validateTitle() {
                if (this.title.length > 0) {
                    this.errors.title = null;
                    return true;
                }
                this.errors.title = "Title is required";
                return false;
            },
            validateDateMatch() {
                if (this.jobEnd < this.jobStart) {
                    return false;
                }
                return true;
            },
            validateDates() {
                this.validateJobEnd();
                this.validateJobStart();
            }
        },
        watch: {
            company: 'validateCompany',
            description: 'validateDescription',
            jobEnd: 'validateDates',
            jobStart: 'validateDates',
            title: 'validateTitle'
        }
    });
}
if (document.getElementById('job-management') !== null) {
    new Vue({
        el: '#job-management',
        components: {
            tableComponent
        },
        data: function () {
            return {
                options: {
                    endpoint: '/api/Job',
                    dataset: {
                        actions: {
                            details: { enabled: true, url: 'Details' },
                            edit: { enabled: true, url: 'Edit' },
                            remove: { enabled: true, url: 'Delete' }
                        },
                        hide:['Id','Description']
                    },
                    key: 'Id',
                    ribbon: [{
                        url: 'Create',
                        icon: 'far fa-plus'
                    }]
                }
            };
        },
        mounted: function () {
        },
        methods: {
            isEmpty() {
                return !this.data.length > 0;
            }
        }
    });
}
if (document.getElementById('manage-index-page') !== null) {
    new Vue({
        el: "#manage-index-page",
        beforeMount() {
            common.get(this.endpoint)
                .then(response => this.source = response.value)
                .catch(error => console.error(error));

            this.bio = this.$el.querySelector('#Account_Bio').value;
            this.contactImage = this.$el.querySelector('#Configuration_ContactImage').value;
            this.cvLink = this.$el.querySelector('#Configuration_CVLink').value;
            this.email = this.$el.querySelector('#Account_Email').value;
            this.firstName = this.$el.querySelector('#Account_FirstName').value;
            this.githubLink = this.$el.querySelector('#Configuration_GithubLink').value;
            this.landingImage = this.$el.querySelector('#Configuration_LandingImage').value;
            this.lastName = this.$el.querySelector('#Account_LastName').value;
            this.linkedInLink = this.$el.querySelector('#Configuration_LinkedInLink').value;
            this.phoneNumberValue = this.$el.querySelector('#Account_PhoneNumber').value.replace(this.numberRegex,'').split('');
            this.profile = this.$el.querySelector('#Account_Link').value;
            this.skillsImage = this.$el.querySelector('#Configuration_SkillImage').value;
            this.title = this.$el.querySelector('#Account_Title').value;
        },
        components: {
            galleryPickerComponent
        },
        data: function () {
            return {
                bio:'',
                contactImage: '',
                cvLink:'',
                email:'',
                endpoint: '/api/MediaLink?$expand=Group',
                firstName: '',
                githubLink:'',
                landingImage: '',
                lastName: '',
                linkedInLink:'',
                phoneNumber: '(###) ###-####',
                phoneNumberValue:[],
                profile: '',
                skillsImage: '',
                source: [],
                title: '',
                numberRegex: /[^0-9]/g,
                userInfoUrl: '/api/Manage/PostUserInfo',
                configUrl: '/api/Manage/PostConfiguration',
                passwordChangeUrl: '/api/Manage/PostPasswordChange',
                loadingUserInfo: false,
                loadingPasswordChange: false,
                loadingSiteConfig: false,
                oldPassword: '',
                newPassword: '',
                confirmPassword:''
            };
        },
        methods: {
            getProfilePic() {
                return this.profile || '/assets/dist/images/default_profile_pic.png';
            },
            submitUserInfo() {
                this.loadingUserInfo = true;
                let phoneNumber = this.phoneNumberValue.length > 0 ? this.phoneNumber : '';
                common.post(this.userInfoUrl, { Email:this.email, FirstName: this.firstName, LastName: this.lastName, Link: this.profile, Title: this.title, PhoneNumber: phoneNumber, Bio: this.bio })
                    .then(response => console.log(response))
                    .catch(response => console.error(response))
                    .finally(() => this.loadingUserInfo = false);
            },
            submitPasswordChange() {
                this.loadingPasswordChange = true;
                common.post(this.passwordChangeUrl, { OldPassword: this.oldPassword, NewPassword: this.newPassword })
                    .then(response => {
                        console.log(response);
                        this.clearPasswordForm();
                    })
                    .catch(response => console.error(response))
                    .finally(() => this.loadingPasswordChange = false);
            },
            submitSiteConfiguration() {
                this.loadingSiteConfig = true;
                common.post(this.configUrl, { LandingImage: this.landingImage, SkillImage: this.skillsImage, ContactImage: this.contactImage, GithubLink: this.githubLink, LinkedInLink: this.linkedInLink, CVLink: this.cvLink })
                    .then(response=>console.log(response))
                    .catch(response => console.error(response))
                    .finally(() => this.loadingSiteConfig = false);
            },
            format(e) {
                if (e.keyCode === 8) {
                    this.phoneNumberValue.pop();
                }
                else {
                    if (parseInt(e.key) >= 0 && this.phoneNumberValue.length < 10) {
                        this.phoneNumberValue.push(e.key);
                    }
                }
            },
            clearPasswordForm() {
                this.newPassword = '';
                this.oldPassword = '';
                this.confirmPassword = '';
            }
        },
        watch: {
            phoneNumberValue: function () {
                this.phoneNumber = `(${this.phoneNumberValue[0] || '#'}${this.phoneNumberValue[1] || '#'}${this.phoneNumberValue[2] || '#'}) ${this.phoneNumberValue[3] || '#'}${this.phoneNumberValue[4] || '#'}${this.phoneNumberValue[5] || '#'}-${this.phoneNumberValue[6] || '#'}${this.phoneNumberValue[7] || '#'}${this.phoneNumberValue[8] || '#'}${this.phoneNumberValue[9] || '#'}`;
            }
        }
    });
}
if (document.getElementById('media-index-page') !== null) {
    new Vue({
        el: "#media-index-page",
        components: {
            storageBarComponent,
            apexBarChart
        },
        data: function () {
            return {
                categories: [],
                groupsCount: 0,
                hiddenData: [],
                imagesCount: 0,
                loading:false,
                max: 0,
                series: [{ name: 'Groups', data: [] }],
                showHidden: false,
                used: 0,
                url: '/api/Bucket/GetStorageInfo'
            };
        },
        methods: {
            fillStorageBar(data) {
                this.max = data.maxValue;
                this.used = data.groups.reduce((_total, current) => _total += current.size, 0);
            },
            fillDisplay(data) {
                this.imagesCount = data.groups.reduce((_total, current) => _total += current.count, 0);
                this.groupsCount = data.groups.length;
            },
            fillChart(data) {
                let seriesData = [];
                let categories = [];
                data.groups.forEach(group => {
                    categories.push(group.name);
                    seriesData.push(group.count);
                });

                this.categories = categories;
                this.series = [{ name: 'Groups', data: seriesData }];
            },
            load() {
                this.loading = true;
                common.get(this.url)
                    .then(data => {
                        if (!this.showHidden) {
                            data.groups = data.groups.filter(group => group.name !== "__thumbnails/");
                        }
                        this.fillStorageBar(data);
                        this.fillDisplay(data);
                        this.fillChart(data);
                    })
                    .catch(error => console.error(error))
                    .finally(() => this.loading = false);
            }
        },
        mounted() {
            this.load();
        },
        watch: {
            showHidden: function () {
                this.load();
            }
        }
    });
}
if (document.getElementById('mediagroup-create-form') !== null) {
    new Vue({
        el: "#mediagroup-create-form",
        components: {
            confirmationComponent
        },
        data: function () {
            return {
                errors: {
                    name: null,
                    result:null
                },
                invalidRegex: /[^a-zA-Z0-9]/g,
                name: '',
                nameRegex: /^[A-Za-z0-9_]*$/,
                normalizedName:'',
                url: '/api/MediaGroup'
            };
        },
        methods: {
            submit() {
                if (this.validateName()) {
                    common.post(this.url, { Name: this.name, NormalizedName: this.normalizedName })
                        .then(() => window.location.href = "Index")
                        .catch(response => { console.log(response); this.errors.result = response.error.message; });
                }
            },
            normalize() {
                this.normalizedName = this.name.toLowerCase().replace(this.invalidRegex, '');
            },
            validateName() {
                if (this.name.trim() === '') {
                    this.errors.name = 'Name is required.';
                    return false;
                }
                else {
                    this.errors.name = null;
                }
                return true;
            }
        },
        watch: {
            name: function () {
                this.validateName();
                this.normalize();
            }
        }
    });
}
if (document.getElementById('mediagroup-delete-form') !== null) {
    new Vue({
        el: "#mediagroup-delete-form",
        data: function () {
            return {
                errors: {
                    result: null
                },
                id: null,
                loading:false,
                url: '/api/MediaGroup'
            };
        },
        beforeMount() {
            this.id = this.$el.querySelector('#Id').value;
        },
        methods: {
            submit() {
                this.loading = true;
                common.delete(`${this.url}(${this.id})`)
                    .then(() => window.location.href = "/Mediagroup/Index")
                    .catch(response => { this.errors.result = response.error.message; })
                    .finally(() => this.loading = false);
            }
        }
    });
}
if (document.getElementById('mediagroup-edit-form') !== null) {
    new Vue({
        el: "#mediagroup-edit-form",
        data: function () {
            return {
                errors: {
                    name: null,
                    result: null
                },
                id: '',
                name: '',
                normalizedName: '',
                invalidRegex: /[^a-zA-Z0-9]/g,
                nameRegex: /^[A-Za-z0-9_]*$/,
                url: '/api/MediaGroup'
            };
        },
        beforeMount() {
            this.id = this.$el.querySelector('#Id').value;
            this.name = this.$el.querySelector('#Name').value;
            this.normalizedName = this.$el.querySelector('#NormalizedName').value;
        },
        methods: {
            submit() {
                if (this.validateName()) {
                    common.put(`${this.url}(${this.id})`, { Id: this.id, Name: this.name, NormalizedName: this.normalizedName })
                        .then(() => window.location.href = "/Mediagroup/Index")
                        .catch(response => { this.errors.result = response.error.message; });
                }
            },
            normalize() {
                this.normalizedName = this.name.toLowerCase().replace(this.invalidRegex, '');
            },
            validateName() {
                if (this.name.trim() === '') {
                    this.errors.name = 'Name is required.';
                    return false;
                }
                else {
                    this.errors.name = null;
                }
                return true;
            }
        },
        watch: {
            name: function () {
                this.validateName();
                this.normalize();
            }
        }
    });
}
if (document.getElementById('mediagroup-management') !== null) {
    new Vue({
        el: '#mediagroup-management',
        components: {
            tableComponent
        },
        data: function () {
            return {
                options: {
                    endpoint: '/api/MediaGroup',
                    dataset: {
                        actions: {
                            details: { enabled: true, url: 'Details' },
                            edit: { enabled: true, url: 'Edit' },
                            remove: { enabled: true, url: 'Delete' }
                        },
                        showKey: false
                    },
                    key: 'Id',
                    ribbon: [{
                        icon: 'far fa-plus',
                        url:'Create'
                        }]
                }
            };
        }
    });
}
if (document.getElementById('medialink-create-form') !== null) {
    new Vue({
        el: "#medialink-create-form",
        components: {
            uploadComponent
        },
        beforeMount() {
            common.get(`${this.url_groups}?$orderby=Name`).then(data => {
                this.groups = data.value;
                if (this.groups.length > 0) {
                    this.selected = this.groups[0].Id;
                }
                else {
                    this.errors.group = 'No media groups available.';
                }
            }).catch(error => console.error(error));
        },
        data: function () {
            return {
                errors: {
                    file: null,
                    group: null,
                    name: null,
                    result:null
                },
                extRegex: /\.[^ /.]+$/,
                file: null,
                groups:[],
                loading:false,
                name: '',
                nameRegex: /^[A-Za-z0-9_]*$/,
                selected:'',
                url: '/api/MediaLink',
                url_groups:'/api/MediaGroup'
            };
        },
        methods: {
            checkForm() {
                let name = this.validateName();
                let file = this.validateFile();
                let group = this.validateGroup();
                return name && file && group;
            },
            submit() {
                if (this.checkForm()) {
                    this.loading = true;
                    const fd = new FormData();
                    fd.append('file', this.file);
                    fd.append('name', `${this.name}.${this.getType(this.file.type)}`);
                    fd.append('group', this.selected);
                    common.postMultiPart(this.url, fd)
                        .then(() => window.location.href = "Index")
                        .catch(response => {
                            this.errors.result = response.error.message;
                        })
                        .finally(() => this.loading = false);
                }
            },
            getGroup() {
                this.groups.forEach(group => {
                    if (group.Id === this.selected)
                        return group;
                });
                return null;
            },
            getType(val) {
                return val.slice(6, val.length);
            },
            validateGroup() {
                if (this.groups.length > 0) {
                    return true;
                }
                return false;
            },
            validateName() {
                if (this.name.trim() === '') {
                    this.errors.name = 'Name is required.';
                    return false;
                }
                else if (!this.name.match(this.nameRegex)) {
                    this.errors.name = 'Name can only consist of letters, numbers and underscore';
                    return false;
                }
                else {
                    this.errors.name = null;
                }
                return true;
            },
            validateFile() {
                if (this.file === null) {
                    this.errors.file = 'Please select an image to upload';
                    return false;
                } else {
                    this.errors.file = null;
                }
                return true;
            }
        },
        watch: {
            file: function (newFile) {
                if (newFile !== null) {
                    this.name = newFile.name.replace(this.extRegex,'');
                }
                else this.name = '';
                this.validateFile();
            },
            name: function () {
                this.validateName();
            }
        }
    });
}
if (document.getElementById('medialink-delete-form') !== null) {
    new Vue({
        el: "#medialink-delete-form",
        components: {
            uploadComponent
        },
        beforeMount() {
            this.id = this.$el.querySelector('#Id').value;
        },
        data: function () {
            return {
                errors: {
                    result: null
                },
                id: null,
                loading:false,
                url: '/api/MediaLink'
            };
        },
        methods: {
            checkForm() {
                return this.id !== null;
            },
            submit() {
                if (this.checkForm()) {
                    this.loading = true;

                    common.delete(`${this.url}(${this.id})`)
                        .then(() => window.location.href = "/Medialink/Index")
                        .catch(response => {
                            this.errors.result = response.error.message;
                        })
                        .finally(() => this.loading = false);
                }
            }
        }
    });
}
if (document.getElementById('medialink-management') !== null) {
    new Vue({
        el: '#medialink-management',
        components: {
            galleryComponent
        },
        data: function () {
            return {
                options: {
                    endpoint: '/api/MediaLink',
                    grouping: {
                        enabled: true,
                        entity: 'Group'
                    },
                    key: 'Id',
                    ribbon: [{
                        icon: 'far fa-plus',
                        url: 'Create'
                    }]
                }
            };
        }
    });
}
if (document.getElementById('skill-create-form') !== null) {
    new Vue({
        el: '#skill-create-form',
        data: function () {
            return {
                errors: {
                    name: null,
                    proficiency:null,
                    result: null
                },
                name: '',
                proficiency:'Basic',
                url: '/api/Skill'
            };
        },
        methods: {
            checkForm() {
                const name = this.validateName();
                const proficiency = this.validateProficiency();

                return name && proficiency;
            },
            submit() {
                common.post(this.url, { Name: this.name, Proficiency: this.proficiency })
                    .then(() => window.location.href = "Index")
                    .catch(response => this.errors.result = response.error.message);
            },
            validateName() {
                if (this.name.length > 0) {
                    this.errors.name = null;
                    return true;
                }
                this.errors.name = 'Skill name is required.';
                return false;
            },
            validateProficiency() {
                if (this.proficiency.length > 0) {
                    this.errors.proficiency = null;
                    return true;
                }
                this.errors.proficiency = 'Proficiency level is required.';
                return false;
            }
        },
        watch: {
            name: 'validateName',
            proficiency:'validateProficiency'
        }
    });
}
if (document.getElementById('skill-delete-form') !== null) {
    new Vue({
        el: '#skill-delete-form',
        beforeMount() {
            this.id = this.$el.querySelector('#Id').value;
        },
        data: function () {
            return {
                errors: {
                    result: null
                },
                id: '',
                url: '/api/Skill'
            };
        },
        methods: {
            submit() {
                common.delete(`${this.url}(${this.id})`)
                    .then(() => window.location.href = "/Skill/Index")
                    .catch(response => this.errors.result = response.error.message);
            }
        }
    });
}
if (document.getElementById('skill-edit-form') !== null) {
    new Vue({
        el: '#skill-edit-form',
        beforeMount() {
            this.id = this.$el.querySelector('#Id').value;
            this.name = this.$el.querySelector('#Name').value;
            this.proficiency = this.$el.querySelector('#Proficiency').value;
        },
        data: function () {
            return {
                errors: {
                    name: null,
                    proficiency: null,
                    result: null
                },
                id:'',
                name: '',
                proficiency: 'Basic',
                url: '/api/Skill'
            };
        },
        methods: {
            checkForm() {
                const name = this.validateName();
                const proficiency = this.validateProficiency();

                return name && proficiency;
            },
            submit() {
                common.put(`${this.url}(${this.id})`, { Id:this.id, Name: this.name, Proficiency: this.proficiency })
                    .then(() => window.location.href = "/Skill/Index")
                    .catch(response => this.errors.result = response.error.message);
            },
            validateName() {
                if (this.name.length > 0) {
                    this.errors.name = null;
                    return true;
                }
                this.errors.name = 'Skill name is required.';
                return false;
            },
            validateProficiency() {
                if (this.proficiency.length > 0) {
                    this.errors.proficiency = null;
                    return true;
                }
                this.errors.proficiency = 'Proficiency level is required.';
                return false;
            }
        },
        watch: {
            name: 'validateName',
            proficiency: 'validateProficiency'
        }
    });
}
if (document.getElementById('skill-management') !== null) {
    new Vue({
        el: '#skill-management',
        components: {
            tableComponent
        },
        data: function () {
            return {
                options: {
                    endpoint: '/api/Skill',
                    dataset: {
                        actions: {
                            details: { enabled: true, url: 'Details' },
                            edit: { enabled: true, url: 'Edit' },
                            remove: { enabled: true, url: 'Delete' }
                        },
                        hide:['Id']
                    },
                    key: 'Id',
                    ribbon: [{
                        url: 'Create',
                        icon: 'far fa-plus'
                    }]
                }
            };
        },
        mounted: function () {
        },
        methods: {
            isEmpty() {
                return !this.data.length > 0;
            }
        }
    });
}
if (document.getElementById('sidebar-toggle-widget') !== null) {
    new Vue({
        el: "#sidebar-toggle-widget",
        data: function () {
            return {
                sidebar:true
            };
        },
        methods: {
            toggleSideBar() {
                this.sidebar = !this.sidebar;
                $("#side-bar").toggleClass('-closed', !this.sidebar);
            }
        },
        mounted() {
            if (localStorage.blogcore_sidebar) {
                this.sidebar = localStorage.blogcore_sidebar === "true";
                $("#side-bar").toggleClass('-closed', !this.sidebar);
            }
        },
        watch: {
            sidebar(newVal) {
                localStorage.blogcore_sidebar = newVal;
            }
        }
    });
}